import rightToLeftCodes from '../utils/rightToLeftCodes.json'

const isRightToLeftLanguage = (languageCode) => {
  return rightToLeftCodes.includes(languageCode.toLowerCase())
}

const limitCharCount = (count, limit) => {
  if (count > limit) {
    const countString = count.toString()
    const overLimitIndex =
      countString.length - (count - limit).toString().length
    const withinLimitPart = countString.substring(0, overLimitIndex)
    const overLimitPart = countString.substring(overLimitIndex)
    return (
      `<span>${withinLimitPart}</span>` +
      `<span class="text-error-100">${overLimitPart}</span>/`
    )
  }
  return `<span>${count}</span>/`
}

const charCountNoTags = (text) => {
  const regex = /\{\d+\}|\{g\d+\}|\{ph\d+\}/g
  const textWithoutTags = text.replace(regex, '')
  return { count: text.replace(regex, '').length, textWithoutTags }
}

function selectText(text) {
  const selection = window.getSelection()
  const range = document.createRange()

  // Clear any previous selections
  selection.removeAllRanges()

  // Select all the content of the div
  range.selectNodeContents(text)
  selection.addRange(range)
}

function areTagsMissing(source, target) {
  const regex = /\{\d+\}|\{g\d+\}|\{ph\d+\}/g
  const sourceTags = source.match(regex) || []
  const targetTags = target.match(regex) || []
  const sourceTagCount = {}
  sourceTags.forEach(
    (tag) => (sourceTagCount[tag] = (sourceTagCount[tag] || 0) + 1)
  )
  const targetTagCount = {}
  targetTags.forEach(
    (tag) => (targetTagCount[tag] = (targetTagCount[tag] || 0) + 1)
  )
  for (const tag in sourceTagCount) {
    if (sourceTagCount[tag] > (targetTagCount[tag] || 0)) return true
  }
  return false
}

function isTagsMismatch(source, target) {
  const regex = /\{\d+\}|\{g\d+\}|\{ph\d+\}/g
  const sourceTags = source.match(regex) || []
  const targetTags = target.match(regex) || []
  if (sourceTags.length !== targetTags.length) return true
  const tagCount = {}
  sourceTags.forEach((tag) => (tagCount[tag] = (tagCount[tag] || 0) + 1))
  targetTags.forEach((tag) => {
    if (tagCount[tag]) {
      tagCount[tag]--
      if (tagCount[tag] === 0) delete tagCount[tag]
    }
  })

  // If tagCount is not empty, then there is a mismatch
  return Object.keys(tagCount).length !== 0
}

function adjustTags(source, target) {
  const regex = /\{\d+\}|\{g\d+\}|\{ph\d+\}/g

  // Tags counter
  function countTags(tags) {
    return tags.reduce((acc, tag) => {
      acc[tag] = (acc[tag] || 0) + 1
      return acc
    }, {})
  }

  // Extract tags from source and target
  const sourceTags = source.match(regex) || []
  const targetTags = target.match(regex) || []
  let adjustedTarget = target

  // Count occurrences of each tag in source and target
  const sourceTagCounts = countTags(sourceTags)
  const targetTagCounts = countTags(targetTags)

  // Remove extra tags from the target
  for (let tag in targetTagCounts) {
    if (targetTagCounts[tag] > (sourceTagCounts[tag] || 0)) {
      let excess = targetTagCounts[tag] - (sourceTagCounts[tag] || 0)
      while (excess > 0) {
        adjustedTarget = adjustedTarget.replace(tag, '')
        excess--
      }
    }
  }

  // Find and append missing tags
  Object.keys(sourceTagCounts).forEach((tag) => {
    let missing = sourceTagCounts[tag] - (targetTagCounts[tag] || 0)
    while (missing > 0) {
      adjustedTarget += tag
      missing--
    }
  })
  return adjustedTarget
}

function parseContent(text, tags, terms, isSource) {
  let modifiedText = text
  let tbMatches = []
  if (terms) {
    const sorted = Object.values(terms).sort((a, b) => {
      const stringA = isSource ? a.source : a.target
      const stringB = isSource ? b.source : b.target
      return stringB.length - stringA.length
    })
    sorted.forEach((term, index) => {
      const termFromTB = isSource ? term.source : term.target
      const escapedTerm = termFromTB
        .trim()
        .replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
      const regex = new RegExp(
        `(?<!\\p{L}|\\p{N})` +
          escapedTerm.replace(/ +$/, '') +
          `(?!\\p{L}|\\p{N})`,
        'giu'
      )
      const source_type = term.source_type

      modifiedText = modifiedText.replace(regex, (match) => {
        const currentIndex = tbMatches.length
        tbMatches.push({
          term: termFromTB,
          match: match,
          index: currentIndex,
          source_type: source_type
        })
        return `tb${currentIndex}tb`
      })
    })
  }
  const regexForTerm = /tb(\d+)tb/
  const regexPattern =
    /(?<=tb\d+tb|\{\d+\}|\{g\d+\}|\{ph\d+\})|(?=tb\d+tb|\{\d+\}|\{g\d+\}|\{ph\d+\})/
  const parts = modifiedText.split(regexPattern)
  const result = parts.reduce((acc, part) => {
    let isTag = false
    let isGroupedTag = false
    let isTerm = false
    let isClosingTag = false
    let isPhTag = false
    let sourceType = null
    if (part) {
      if (part.match(/^(tb\d+tb|\{\d+\}|\{g\d+\}|\{ph\d+\})$/)) {
        isTag = /\{\d+\}|\{g\d+\}|\{ph\d+\}/.test(part)
        isTerm = regexForTerm.test(part)
        if (tags && isTag) {
          isGroupedTag = /\{g\d+\}/.test(part)
          isPhTag = /\{ph\d+\}/.test(part)
          if (!isGroupedTag && !isPhTag) {
            const number = part.match(/\{(\d+)\}/)[1]
            isClosingTag = tags[number] && tags[number].startsWith('</')
          }
        }
        if (isTerm) {
          const match = part.match(regexForTerm)
          const extractedIndex = parseInt(match[1], 10)
          const matchObject = tbMatches.find((m) => m.index === extractedIndex)
          if (matchObject) {
            part = matchObject.match
            sourceType = matchObject.source_type
          }
        }
        acc.push({
          text: part,
          isTerm,
          sourceType,
          isTag,
          tagType: {
            isGroupedTag,
            isClosingTag,
            isPhTag
          }
        })
      } else {
        // Check if the last element is regular text to merge
        if (
          acc.length > 0 &&
          !acc[acc.length - 1].isTerm &&
          !acc[acc.length - 1].isTag
        ) {
          acc[acc.length - 1].text += ` ${part}`
        } else {
          acc.push({
            text: part,
            isTerm,
            sourceType,
            isTag,
            tagType: {
              isGroupedTag,
              isClosingTag,
              isPhTag
            }
          })
        }
      }
    }
    return acc
  }, [])
  return { result, tbMatches }
}

const revertParsedContent = (htmlText, removeText) => {
  const text = document.createElement('textarea')
  text.innerText = htmlText
  return text.value
    .replace(/\u00A0/g, ' ')
    .replace(/ +/g, ' ')
    .replace(removeText, '')
    .replace(/<style[^>]*>.*?<\/style>|<\/?[a-z][^>]*>/gi, '')
}

export {
  adjustTags,
  areTagsMissing,
  charCountNoTags,
  isRightToLeftLanguage,
  isTagsMismatch,
  limitCharCount,
  parseContent,
  revertParsedContent,
  selectText
}
