function isTextTruncated(element) {
  if (!element) return false

  const range = document.createRange()
  range.selectNodeContents(element)
  const textWidth = range.getBoundingClientRect().width
  const containerWidth = element.getBoundingClientRect().width

  return textWidth > containerWidth
}

export { isTextTruncated }
